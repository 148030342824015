import React from "react";
import GithubProjectCover from "../assets/githubfinder-cover.png";
import FeedbackProjectCover from "../assets/feedbackapp-cover.png";
import ReliqProjectCover from "../assets/reliq-cover.png";

import { HashLink as Link } from "react-router-hash-link";

function Projects() {
  return (
    <div className="grid items-center p-8">
      <h2 className="flex justify-center items-center font-bold text-3xl pb-6 uppercase">
        Projects
      </h2>
      <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 mb-8 md:gap-6">
        {/* Feedback UI Project */}
        <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-400 mb-8 md:mb-0">
          <div className="relative pb-10/12">
            <Link to="https://feedback-ui-by-brent.netlify.app/">
              <img
                className="opacity-40 blur-[1px] absolute h-full w-full object-cover rounded-t-lg"
                src={FeedbackProjectCover}
                alt=""
              />
            </Link>
          </div>

          <div className="p-5">
            <Link to="https://precious-pothos-e19bfc.netlify.app/">
              <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                Feedback UI
              </h5>
            </Link>
            <p className="mb-3 text-sm font-normal text-gray-700 dark:text-gray-400">
              Feedback app utilizing React components, context and hooks.
            </p>
            <Link
              to="https://precious-pothos-e19bfc.netlify.app/"
              className="inline-flex items-center px-3 py-2 text-xs font-normal text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Visit project
              <svg
                aria-hidden="true"
                className="w-4 h-4 ml-2 -mr-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </Link>
          </div>
        </div>

        {/* Github Finder Project */}
        <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-400 mb-8 md:mb-0">
          <div className="relative pb-10/12">
            <Link to="https://github.com/brentmanzo/github-finder">
              <img
                className="opacity-40 blur-[1px] absolute h-full w-full object-cover rounded-t-lg"
                src={GithubProjectCover}
                alt=""
              />
            </Link>
          </div>
          <div className="p-5">
            <Link to="https://github.com/brentmanzo/github-finder">
              <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                Github Finder
              </h5>
            </Link>
            <p className="mb-3 text-sm font-normal text-gray-700 dark:text-gray-400">
              Project built with React utilizing Github API from Udemy.
            </p>
            <Link
              to="https://github.com/brentmanzo/github-finder"
              className="inline-flex items-center px-3 py-2 text-xs font-normal text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Visit project
              <svg
                aria-hidden="true"
                className="w-4 h-4 ml-2 -mr-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </Link>
          </div>
        </div>

        {/* Reliq Freelance Project */}
        <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-400 mb-8 md:mb-0">
          <div className="relative pb-10/12">
            <Link to="https://brentmanzo.github.io/reliq/">
              <img
                className="opacity-40 blur-[1px] absolute h-full w-full object-cover rounded-t-lg"
                src={ReliqProjectCover}
                alt=""
              />
            </Link>
          </div>
          <div className="p-5">
            <Link to="https://github.com/brentmanzo/reliq">
              <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                Reliq Mint Page
              </h5>
            </Link>
            <p className="mb-3 text-sm font-normal text-gray-700 dark:text-gray-400">
              Front-end page built on SASS for a token generating project by
              Reliq.
            </p>
            <Link
              to="https://github.com/brentmanzo/github-finder"
              className="inline-flex items-center px-3 py-2 text-xs font-normal text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Visit project
              <svg
                aria-hidden="true"
                className="w-4 h-4 ml-2 -mr-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;
