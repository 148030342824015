import React from "react";
import profileimg from "../assets/brent-profile-bw.JPG";

function Intro() {
  return (
    <div className="relative flex justify-center md:h-screen items-center grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 mb-8 md:gap-8">
      <div className="flex justify-center custom-card-image mb-6 md:mb-2">
        <div className="rounded-lg shadow-xl md:float-right max-w-[80%] card image-full">
          <figure>
            <img src={profileimg} alt="" />
          </figure>
          {/* <div className="card-body justify-end">
            <h2 className="card-title mb-0">22</h2>
            <p className="flex-grow-0"></p>
          </div> */}
        </div>
      </div>

      <div className="col-span-3 md:col-span-2">
        <div className="flex flex-col md:flex-none items-center md:items-start mb-6">
          <h1 className="text-3xl card-title">Brent Millen Manzo</h1>
          <div className="flex flex-row my-1">
            <span className="mr-1 text-white inline-flex items-center justify-center px-2 py-1 text-xs leading-none bg-gray-600 rounded-full">
              Developer
            </span>
            <span className="mx-1 text-white inline-flex items-center justify-center px-2 py-1 text-xs leading-none bg-gray-400 rounded-full">
              PH
            </span>
          </div>

          {/* <p className="my-1">
            Application Engineer - specializing in full stack web development
          </p> */}
          <p className="my-1 w-11/12">
            Applications Engineer, specializing in full stack web development -
            My goal as a developer is to create systems and solutions for
            efficiency. I am driven to maximize our current technologies for
            daily use.
          </p>
          <div className="mt-4 card card-actions">
            {/* <a
              href={`https://github.com/brentmanzo`}
              target="_blank"
              rel="noreferrer"
              className="btn btn-outline"
            >
              Github
            </a> */}
          </div>
          {/* Project partials anchor */}
          <span id="projects" className="absolute bottom-44"></span>
        </div>

        {/* <div className="w-full rounded-lg shadow-md bg-base-100 stats">
          <div className="stat">
            <div className="stat-title text-sm">Location</div>
            <div className="text-base stat-value">PH</div>
          </div>

          <div className="stat">
            <div className="stat-title text-sm">Github</div>
            <div className="text-base stat-value">
              <a
                href={`https://github.com/brentmanzo`}
                target="_blank"
                rel="noreferrer"
              >
                <code>/brentmanzo</code>
              </a>
            </div>
          </div>

          <div className="stat">
            <div className="stat-title text-sm">Tech</div>
            <div className="text-base stat-value">HTML-JS-CSS</div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Intro;
