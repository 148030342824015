import React from "react";
import { Link } from "react-router-dom";
import tailwindlogo from "../assets/tailwindcss-logo.svg";
import reactlogo from "../assets/react-icon.png";
import nodejslogo from "../assets/nodejs-logo.png";
import ec2logo from "../assets/ec2-logo.svg";
import rdslogo from "../assets/rds-logo.png";
import netlifylogo from "../assets/netlify-logo.png";
import vscodelogo from "../assets/vscode-logo.png";

import certintjs from "../assets/cert-intjs.png";
import certazure from "../assets/cert-azure.png";
import certaws from "../assets/cert-aws.png";

function Background() {
  return (
    <div id="background" className="grid items-center md:h-screen">
      <div className="p-8">
        <div className="flex flex-col items-center justify-center">
          <div className="text-center mt-14 mb-8">
            <h3 className="font-bold text-2xl">Bachelor's Degree</h3>
            <p className="font-medium text-xl">
              in <br /> Information Technology <br />
            </p>
            <p className="italic"> Major in Mobile and Internet Technologies</p>
          </div>
          <div className="text-center mb-8 py-6">
            <h3 className="font-bold">Technologies</h3>
            <div className="flex w-full flex-wrap mt-2">
              <div className="group relative flex">
                <img
                  src={reactlogo}
                  alt="React"
                  className="items-center justify-center mr-2 h-16 w-16 items-center justify-center"
                />
                <span className="inset-x-2 -ml-2 mt-2 transition-all absolute top-16 scale-0 rounded bg-gray-400 p-2 text-xs text-white group-hover:scale-100">
                  React JS
                </span>
              </div>

              <div className="group relative flex">
                <img
                  src={nodejslogo}
                  alt="Node JS"
                  className="items-center justify-center mr-2 h-16 w-16 items-center justify-center"
                />
                <span className="inset-x-2 -ml-2 mt-2 transition-all absolute top-16 scale-0 rounded bg-gray-400 p-2 text-xs text-white group-hover:scale-100">
                  Node JS
                </span>
              </div>

              <div className="group relative flex">
                <img
                  src={tailwindlogo}
                  alt="Tailwind CSS"
                  className="items-center justify-center mr-2 h-16 w-16 items-center justify-center"
                />
                <span className="inset-x-2 -ml-2 mt-2 transition-all absolute top-16 scale-0 rounded bg-gray-400 p-2 text-xs text-white group-hover:scale-100">
                  Tailwind CSS
                </span>
              </div>

              <div className="group relative flex">
                <img
                  src={ec2logo}
                  alt="AWS EC2"
                  className="items-center justify-center mr-2 h-16 w-16 items-center justify-center"
                />
                <span className="inset-x-2 -ml-2 mt-2 transition-all absolute top-16 scale-0 rounded bg-gray-400 p-2 text-xs text-white group-hover:scale-100">
                  Amazon EC2
                </span>
              </div>

              <div className="group relative flex">
                <img
                  src={rdslogo}
                  alt="AWS RDS"
                  className="items-center justify-center mr-3 h-16 w-16 items-center justify-center"
                />
                <span className="inset-x-2 -ml-3 mt-2 transition-all absolute top-16 scale-0 rounded bg-gray-400 p-2 text-xs text-white group-hover:scale-100">
                  AWS Relational Database Service
                </span>
              </div>

              <div className="group relative flex">
                <img
                  src={netlifylogo}
                  alt="Netlify"
                  className="items-center justify-center mr-3 h-16 w-16 items-center justify-center"
                />
                <span className="inset-x-2 -ml-2 mt-2 transition-all absolute top-16 scale-0 rounded bg-gray-400 p-2 text-xs text-white group-hover:scale-100">
                  Netlify
                </span>
              </div>

              <div className="group relative flex">
                <img
                  src={vscodelogo}
                  alt="Visual Studio Code"
                  className="items-center justify-center mr-3 h-16 w-16 items-center justify-center"
                />
                <span className="inset-x-2 -ml-2 mt-2 transition-all absolute top-16 scale-0 rounded bg-gray-400 p-2 text-xs text-white ease-in-out group-hover:scale-100">
                  Visual Studio Code
                </span>
              </div>
            </div>
          </div>

          <div className="text-center mb-8 py-6">
            <h3 className="font-bold mb-4">Certifications and Trainings</h3>

            <div className="grid grid-cols-4 gap-4">
              <div className="mb-4 md:mb-0">
                <Link to="https://www.udemy.com/certificate/UC-787cddef-c4ac-4789-90ea-dd2d4d4a7535/">
                  <div className="relative max-w-xs pb-3/4">
                    <img
                      src="https://udemy-certificate.s3.amazonaws.com/image/UC-787cddef-c4ac-4789-90ea-dd2d4d4a7535.jpg?v=1672926595000"
                      className="border-solid border-2 border-gray-400 absolute w-full h-full object-cover max-w-xs rounded-lg"
                      alt="React Front to Back"
                    />
                    <div className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed opacity-0 hover:opacity-40 transition duration-300 ease-in-out bg-white text-black"></div>
                  </div>
                </Link>
                <h2 className="text-xs md:text-sm px-4">
                  React Front to Back 2022
                </h2>
              </div>

              <div className="mb-4 md:mb-0">
                <Link to="https://www.codecademy.com/profiles/cloud5763996966/certificates/512386fdc7f6c934f98b01e6afa8285a">
                  <div className="relative max-w-xs pb-3/4">
                    <img
                      src={certintjs}
                      className="border-solid border-2 border-gray-400 absolute w-full h-full object-cover max-w-xs rounded-lg"
                      alt="Codecademy: Intermediate JS"
                    />
                    <div className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed opacity-0 hover:opacity-40 transition duration-300 ease-in-out bg-white text-black"></div>
                  </div>
                </Link>
                <h2 className="text-xs md:text-sm px-4">
                  Codecademy: Intermediate JS
                </h2>
              </div>
              <div className="mb-4 md:mb-0">
                <Link to="https://www.credly.com/badges/bbb3a567-4781-4b1e-866f-a43866bfd3bf">
                  <div className="relative max-w-xs pb-3/4">
                    <img
                      src={certazure}
                      className="border-solid border-2 border-gray-400 absolute w-full h-full object-cover max-w-xs rounded-lg"
                      alt="Azure Fundamentals"
                    />
                    <div className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed opacity-0 hover:opacity-40 transition duration-300 ease-in-out bg-white text-black"></div>
                  </div>
                </Link>
                <h2 className="text-xs md:text-sm px-4">
                  Microsoft: Azure Fundamentals
                </h2>
              </div>
              <div className="mb-4 md:mb-0">
                <Link to="https://www.credly.com/badges/26679dbb-45a7-435d-b5f8-1d5a44eadc12">
                  <div className="relative max-w-xs pb-3/4">
                    <img
                      src={certaws}
                      className="border-solid border-2 border-gray-400 absolute w-full h-full object-cover max-w-xs rounded-lg"
                      alt="AWS Academy Graduate - AWS Academy Cloud Foundations"
                    />
                    <div className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed opacity-0 hover:opacity-40 transition duration-300 ease-in-out bg-white text-black"></div>
                  </div>
                </Link>
                <h2 className="text-xs md:text-sm px-4">
                  AWS Academy Cloud Foundations
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Background;
